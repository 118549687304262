import * as React from "react";
import Layout from "../../components/Layout";

const TermsPage = () => {
  return (
    <Layout pageTitle="Terms and Conditions">
      <h1 className="text-2xl font-bold">Terms and Conditions</h1>
    </Layout>
  );
};

export default TermsPage;
